<template>
    <section class="shoppingcart">
        <InventoryLookup
            :side="selectedLens.opEye"
            :showModal.sync="showInventoryModal"
            :preOpDataId="selectedLens.preOpDataId"
            :lengthOptions="lengthOptions"
            :selectedLengthOption="suggestedLengthOption.value"
            :lensInformation="inventoryResults.lensInformation"
            :targetLensDescription="targetLensDescription"
            :allLensInfo="inventoryResults.allLensInfo"
            @on-locked="onLocked"
            @on-selected-length-change="onAddLenses(selectedLens, $event)"
        />
        <span :class="titleClass">{{ titleComputed }}</span>
        <div class="lenses pt-4">
            <b-button
                v-b-toggle.lenses-collapse
                block
                variant="light"
                class="pl-1 text-subheader text-left font-weight-light bg-gray-lighter text-black"
            >
                <b-icon
                    class="when-closed align-middle mx-1"
                    icon="chevron-right"
                    variant="dark"
                    font-scale="0.9"
                />
                <b-icon
                    class="when-open align-middle mx-1"
                    icon="chevron-down"
                    variant="dark"
                    font-scale="0.9"
                />
                {{ t('preopreport_Lenses') }}
                <span class="ml-2">({{ lensesCount }})</span></b-button
            >
            <b-collapse id="lenses-collapse" visible accordion="lenses" role="tabpanel">
                <div
                    v-for="lensGroup in lensGroups"
                    :key="`${lensGroup.patientId}`"
                    class="lens-group"
                >
                    <h5 class="text-center my-3">
                        {{ t('checkout_Patient') }}:
                        <custom-router-link
                            :to="{
                                name: 'PatientDetails',
                                params: {
                                    patientId: lensGroup.patientId,
                                },
                            }"
                            class="text-primary font-weight-bold"
                        >
                            <u>{{ lensGroup.patientOcosId }}</u>
                        </custom-router-link>
                    </h5>
                    <LensCard
                        v-for="lens in lensGroup.items"
                        :key="lens.shoppingCartItemId"
                        :lens="lens"
                        :readonly="readonly || !hasOrderingPermission"
                        :show-add-buttom="canOrderExtraLenses(lens)"
                        :show-primary-lens-status="activeCustomer.maxLenses > 1"
                        :show-expiration-window="true"
                        :additional-lens-status-key-text="`checkout_AdditionalLensStatus_${currentZone}`"
                        @on-add="onAddLenses"
                        @on-remove="onRemoveLens"
                    />
                </div>
            </b-collapse>
        </div>
        <div class="accessories pt-1" v-if="!isDistributorSurgeonMode">
            <b-button
                v-b-toggle.accessories-collapse
                block
                variant="light"
                class="pl-1 text-subheader text-left font-weight-light bg-gray-lighter text-black"
            >
                <b-icon
                    class="when-closed align-middle mx-1"
                    icon="chevron-right"
                    variant="dark"
                    font-scale="0.9"
                />
                <b-icon
                    class="when-open align-middle mx-1"
                    icon="chevron-down"
                    variant="dark"
                    font-scale="0.9"
                />
                {{ t('checkout_Accessories') }}
                <span class="ml-2">({{ accessoriesCount }})</span></b-button
            >
            <b-collapse id="accessories-collapse" visible accordion="accessories" role="tabpanel">
                <AccesoryCard
                    v-for="accessory in accessories"
                    :key="accessory.shoppingCartItemId"
                    :accessory="accessory"
                    :readonly="readonly || !hasOrderingPermission"
                    :failedRegulatoryCheckItems="failedRegulatoryCheckItem"
                />
            </b-collapse>
        </div>
    </section>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import get from 'lodash/get';
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';
import LensCard from '@/components/LensCard.vue';
import AccesoryCard from '@/components/AccesoryCard.vue';
import InventoryLookup from '@/components/InventoryLookup.vue';
import {ITEM_TYPES} from '@/store/modules/cart';
import {ZoneCodes} from '@/constants/zone';
import {decimalSeparatorFormatter, formatNumber} from '@/utilities/formatters';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';

export default {
    name: 'ShoppingCart',
    components: {LensCard, AccesoryCard, InventoryLookup},
    props: {
        title: {
            type: String,
            default: 'Contents',
        },
        titleClass: {
            type: String,
            default: 'h3',
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selectedLens: {},
            showInventoryModal: false,
        };
    },
    computed: {
        ...mapGetters('user', ['activeCustomer']),
        ...mapGetters('cart', ['lensesCount', 'accessoriesCount']),
        ...mapGetters('zone', ['currentZone']),
        ...mapGetters('permissions', ['permissions']),
        ...mapGetters('user', ['currentUser']),
        ...mapState({
            lensGroups: (state) => state.cart.shoppingCart.lensItemsByPatientId,
            accessories: function (state) {
                let accessories = state.cart.shoppingCart.items.filter(
                    (item) => item.itemType === ITEM_TYPES.ACCESSORY
                );
                return accessories.sort((a, b) => b.automaticallyAdded - a.automaticallyAdded);
            },
            inventoryResults: function (state) {
                return get(state.preopdata.inventoryResults, this.selectedLens.opEye, {});
            },
            failedRegulatoryCheckItem: function (state) {
                return state.cart.failedRegulatoryCheckItemCartIds;
            },
        }),
        hasOrderingPermission() {
            return this.checkPermissions({
                [PERMISSIONS.ORDERING]: [PERMISSIONS_VALUES.READ_WRITE],
            });
        },
        isDistributorSurgeonMode() {
            return this.checkPermissions({
                [PERMISSIONS.DISTRIBUTOR_MODE]: [PERMISSIONS_VALUES.SURGEON],
            });
        },
        titleComputed() {
            if (this.title == 'Contents') return this.t('checkout_Contents');

            return this.title;
        },
        targetLensDescription() {
            return decimalSeparatorFormatter(
                this.inventoryResults.targetLensDescription,
                this.currentUser.decimalSeparator
            );
        },
        suggestedLengthOption() {
            const LABELS = {
                [ZoneCodes.OUS]: '',
                [ZoneCodes.US]: this.t('preop_DFURecommended'),
            };
            // Dividing by 10 because the calculator returns the ideal length as a non-decimal int that is the length multiplied by 10
            const value = this.inventoryResults.lensInformation?.length / 10;
            return {
                value,
                text: `${formatNumber(value, 1, this.decimalSeparator)} mm ${
                    LABELS[this.currentZone]
                }`,
            };
        },
        lengthOptions() {
            // Sets lengthOptions from lensInformation as it can change depending on the calculation.
            const options = (cloneDeep(this.inventoryResults.lensInformation?.lengthOptions) || [])
                .sort()
                .map((value) => ({
                    value: parseFloat(value),
                    text:
                        this.suggestedLengthOption.value == value
                            ? this.suggestedLengthOption.text
                            : `${formatNumber(value, 1, this.decimalSeparator)} mm`,
                }));

            // In case not lengthOptions were returned pushing default one
            if (!options.length) {
                const option = this.suggestedLengthOption.value
                    ? this.suggestedLengthOption
                    : {value: 0, text: this.t('notAvailable')};
                options.push(option);
            }
            return options;
        },
    },
    methods: {
        checkPermissions(permissionsToCheck) {
            return checkPermissions(
                permissionsToCheck,
                this.permissions,
                this.currentUser.accessPermissions
            );
        },
        canOrderExtraLenses(lens) {
            return !lens.isMto && this.activeCustomer.maxLenses > 1;
        },
        async onRemoveLens(lens) {
            try {
                const {success, error} = await this.blockingRequest(
                    'cart/removeItem',
                    lens.shoppingCartItemId
                );
                if (!success) throw error;
            } catch (error) {
                this.$bvToast.toast(
                    error?.errors?.join(' ') || 'Something went wrong removing lens',
                    {
                        title: error?.errorCode || this.t('warning'),
                        variant: 'warning',
                        solid: true,
                    }
                );
            }
        },
        async onAddLenses(lens, inventoryLookupChangeRequest = {}) {
            this.selectedLens = lens;
            try {
                const payload = {
                    shoppingCartItemId: this.selectedLens.shoppingCartItemId,
                    length: inventoryLookupChangeRequest.selectedLength?.toString(),
                    ...pick(inventoryLookupChangeRequest, ['pendingAddToCart', 'pendingReserve']),
                };
                const {success, error} = await this.blockingRequest('cart/fetchAvailableLenses', {
                    side: this.selectedLens.opEye,
                    payload,
                });
                if (success) {
                    const {success, error} = await this.blockingRequest(
                        'patient/fetchPrimaryLens',
                        {
                            patientId: this.selectedLens.patientId,
                            eye: this.selectedLens.opEye,
                        }
                    );
                    if (success) {
                        this.showInventoryModal = true;
                    } else {
                        throw error;
                    }
                } else {
                    throw error;
                }
            } catch (error) {
                this.$bvToast.toast(error?.errors?.join(' ') || this.t('somethingWentWrong'), {
                    title: error?.errorCode || this.t('warning'),
                    variant: 'warning',
                    solid: true,
                });
            }
        },
        async onLocked() {
            await this.blockingRequest('cart/fetchItems');
        },
    },
};
</script>
<style lang="scss">
@import '../../assets/css/variables';

.lens-card:last-child {
    border-bottom: 0px !important; /* bootstrap override without it */
}
.lens-group {
    border-bottom: 3px solid $gray-dark;
    &:last-child {
        border-bottom: 0px;
    }
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
    display: none;
}
</style>
